import { gsap } from 'gsap'
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import Cursor from './Cursor'

gsap.registerPlugin(DrawSVGPlugin)

export default class ConsentManager {
  constructor() {
    this.consentTarget = document.querySelector('.js-consent-target')
    this.consentCloseTarget = document.querySelector('.js-consent-close-target')
  }

  init() {
    this._setupListeners()
    this._initTimeline()
  }

  _setupListeners() {
    this.consentCloseTarget.addEventListener('click', e => this._onClick(e), { once: true })
  }

  _onClick() {
    Cursor.onMouseleave()

    document.querySelector('[data-router-wrapper]').scrollTo(0, 0)

    const scriptImport = import('../site.js')

    Promise.all([scriptImport, this.tl.play()])
      .then(e => e[0].render())
  }

  _initTimeline() {
    this.tl = gsap.timeline({ paused: true })

    const clipPathProp = gsap.utils.checkPrefix('clip-path')

    this.tl
      .to('[data-intro-overlay]', {
        [clipPathProp]: 'polygon(0% -1%, 100% -1%, 100% 101%, 0% 101%)',
        duration: 1.5,
        ease: 'expo.inOut'
      })
  }
}
