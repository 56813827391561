export default class Utils {
  static isMobile() {
    return navigator.userAgent.match(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/)
  }

  static addScrollEvents({ instance }) {
    if (!!!instance.scroll_events) instance.scroll_events = []
    const fn = () => instance._onScroll()
    instance.scroll_events.push(fn)
    window.APP.scroll_events.push(fn)
  }

  static addResizeEvents({ instance }) {
    if (!!!instance.resize_events) instance.resize_events = []
    const fn = () => instance._onResize()
    instance.resize_events.push(fn)
    window.APP.resize_events.push(fn)
  }

  static removeEvents({ instance }) {
    if (!!instance.scroll_events) {
      instance.scroll_events.forEach(event => {
        const i = window.APP.scroll_events.findIndex(e => e === event)
        window.APP.scroll_events.splice(i, 1)
      })
    }

    if (!!instance.resize_events) {
      instance.resize_events.forEach(event => {
        const i = window.APP.resize_events.findIndex(e => e === event)
        window.APP.resize_events.splice(i, 1)
      })
    }
  }

  static throttle(func, ms = 50) {
    let to
    let wait = false

    return (...args) => {
      let later = () => {
        func.apply(window, args)
      }

      if(!wait)  {
        later()

        wait = true

        to = setTimeout(() => {
          wait = false
        }, ms)
      }
    }
  }

  static isTouch() {
    try {
      document.createEvent("TouchEvent")
      return true
    } catch (e) {
      return false
    }
  }

  // REF: https://stackoverflow.com/a/37826698
  static arrayChunk(arr, perChunk) {
    return arr.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index/perChunk)

      if(!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }

  /**
   *
   * @param {String} url The URL of the JPG or PNG image
   * @param {Boolean} useAvif Whether to look for AVIF images. If `false`, use WebP instead.
   * @returns The URL of the image in AVIF or WebP format.
   */
  static replaceImageFormat(url, useAvif = false) {
    const browserSupportsAvif = document.documentElement.classList.contains('avif')
    const browserSupportsWebp = document.documentElement.classList.contains('webp')

    // Return the original URL if the browser doesn't support nor AVIF nor WebP images
    if (!browserSupportsWebp && !browserSupportsAvif)
      return url

    const ext = browserSupportsAvif && useAvif ? 'avif' : 'webp'
    const urlRegex = new RegExp(/[\w-]+\.(jpg|png)/, 'g')
    const matches = urlRegex.exec(url)

    // Prepend the `ext` folder to the URL
    let newUrl = url.replace(matches[0], `_${ext}/${matches[0]}`)

    // Change the extension of the file
    newUrl = newUrl.replace(matches[1], ext)

    return newUrl
  }
}
