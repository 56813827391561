import Utils from "./Utils"

export default class ViewportUnitManager {
  constructor() {
    this.vh = 0
    this.vw = 0
    this.windowSize = {
      width: 0,
      height: 0
    }

    this._resizeCb = () => this._onResize()

    this.init()
  }

  init() {
    this._setViewportCSSVars()

    document.documentElement.style.setProperty('--vhu', `${this.windowSize.height * 0.01}px`)
    window.addEventListener('resize', this._resizeCb, { passive: true })
  }

  _onResize() {
    if(Utils.isMobile()) {
      if(this.windowSize.width != window.innerWidth) {
        this._setViewportCSSVars()
        document.documentElement.style.setProperty('--vhu', `${window.innerHeight * 0.01}px`)
      }
    } else {
      this._setViewportCSSVars()
    }
  }

  _setViewportCSSVars() {
    this.vh = window.innerHeight * 0.01
    this.vw = document.documentElement.clientWidth * 0.01
    this.aspectRatio = window.innerHeight / window.innerWidth

    document.documentElement.style.setProperty('--vh', `${this.vh}px`)
    document.documentElement.style.setProperty('--vw', `${this.vw}px`)
    document.documentElement.style.setProperty('--aspect-ratio', this.aspectRatio)

    this.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
}
