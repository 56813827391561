import { gsap } from 'gsap'
import MorphSVGPlugin from 'gsap/MorphSVGPlugin'

gsap.registerPlugin(MorphSVGPlugin)

const cursor = {
  el: document.querySelector('.js-cursor'),
  path: document.querySelectorAll('.js-cursor-path')
}
const diamondPath = 'M50 42.929L57.071 50 50 57.071 42.929 50z'
const circlePath = 'M25.105 25.105c13.473-13.473 35.317-13.473 48.79 0 13.473 13.473 13.473 35.317 0 48.79-13.473 13.473-35.317 13.473-48.79 0-13.473-13.473-13.473-35.317 0-48.79z'

const mouseenterCb = () => Cursor.onMouseenter()
const mouseleaveCb = () => Cursor.onMouseleave()
const mousemoveCb = e => Cursor.onMousemove(e)

export default class Cursor {
  static init() {
    document.addEventListener('mousemove', mousemoveCb)
    Cursor.update()
  }

  static update() {
    cursor.targets = document.querySelectorAll('[data-cursor]')

    cursor.targets.forEach(t => {
      t.addEventListener('mouseenter', mouseenterCb)
      t.addEventListener('mouseleave', mouseleaveCb)
    })
  }

  static destroy() {
    cursor.targets.forEach(t => {
      t.removeEventListener('mouseenter', mouseenterCb)
      t.removeEventListener('mouseleave', mouseleaveCb)
    })
  }

  static onMouseenter() {
    gsap.to(cursor.path, {
      duration: 0.3,
      morphSVG: circlePath,
      ease: 'sine.out',
      fill: 'rgba(189, 152, 85, 0)',
      overwrite: true
    })
  }

  static onMouseleave() {
    gsap.to(cursor.path, {
      duration: 0.4,
      ease: 'sine.out',
      morphSVG: diamondPath,
      fill: 'rgba(189, 152, 85, 1)',
      overwrite: true
    })
  }

  static onMousemove(e) {
    const x = e.clientX - cursor.el.clientWidth / 2
    const y = e.clientY - cursor.el.clientHeight / 2
    gsap.to(cursor.el, { duration: 1, x, y })
  }
}
