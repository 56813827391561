(function checkImageSupport() {
  let checkCount = 0,
      formatFound = false

  function setHTMLClass(height, className) {
    checkCount++

    if (height == 2) {
      formatFound = true
      document.documentElement.classList.add(className)
    } else {
      document.documentElement.classList.add(`not-${className}`)
    }
  }

  var AVIF = new Image()
  AVIF.onload = AVIF.onerror = function () {
    setHTMLClass(AVIF.height, 'avif')
  }
  AVIF.src = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A='

  var WebP = new Image()
  WebP.onload = WebP.onerror = function() {
    setHTMLClass(WebP.height, 'webp')
  }
  WebP.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAUAAAAfQ//73v/+BiOh/AAA='
})()
