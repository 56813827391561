import './global/Signature'
import ViewportUnitManager from './global/ViewportUnitManager'
import ConsentManager from './global/ConsentManager'
import Cursor from './global/Cursor'
import './global/ImageSupport'

new ViewportUnitManager()
new ConsentManager().init()
Cursor.init()

// Add a CSS class to the HTML element if coming from the Ecom site.
// This determines what text display on the age gate.
const isFromEcomSite = document.referrer.indexOf('shop') > -1
if (isFromEcomSite) {
  document.documentElement.classList.add('is-from-ecom-site')
}
